//
// SearchUser
//
import React from "react"
import {Context} from '../App'
import {UserType} from '../caltypes'
import {UsersTable} from './UsersTable'
import {Btn, InputText, Text, InputGrp} from './Misc'

//
// ●SearchUser
//
export const SearchUser= ({cb}:{
  cb: (u: UserType) => void;
}): JSX.Element => {
  const {socket} = React.useContext(Context)
  const [users, setUsers] = React.useState<UserType[]>([])
  const [msg, setMsg] = React.useState<string>('(検索エラーなどが表示されます)')
  // search用
  const [username_hint, setUsername_hint] = React.useState<string>('')
  const [kname_hint, setKname_hint] = React.useState<string>('')
  const [addr1_hint, setAddr1_hint] = React.useState<string>('')

  //
  // socket on/off subscribes
  //
  React.useEffect( ()=>{
    socket.on('search-users', (users : UserType[])=>{
      setUsers(users)
      setMsg(`ログインIDを選んでください (${users.length} 件)`)
    })
    return ()=>{
      socket.off('search-users')
    }
  }, [socket, setUsers])

  //
  // clear 編集用項目など、再初期化
  //
  const clear= () => {
    setMsg('(検索エラーなどが表示されます)')
    //
    setKname_hint('')
    setUsername_hint('')
    setAddr1_hint('')
    //
   
    setUsers([])
   //
  }
  //
  // handlers
  //
  const hSearch = (e: React.MouseEvent<HTMLButtonElement>) => {
    //console.log(`@@@'${username_hint}, ${kname_hint}, ${addr1_hint}' を検索実行`)
    setMsg('(検索エラーなどが表示されます)')
    socket.emit('search-users', {username_hint, kname_hint, addr1_hint})
  }
  
  //
  // render
  //
  return(<>

    <InputGrp>
      <Btn onClick={hSearch}>検索</Btn>
      <Text>{msg}</Text>
      <Btn onClick={clear}>クリア</Btn>
    </InputGrp>

    <InputGrp>
      <InputText  placeholder="漢字名によるヒント"
        value={kname_hint} onChange={ e => setKname_hint(e.target.value)} />
    </InputGrp>
        
    <InputGrp>
      <InputText placeholder="ログインIDで検索"
        value={username_hint} onChange={ e => setUsername_hint(e.target.value)} />         
    </InputGrp>

    <InputGrp>
      <InputText placeholder="住所でさがす"
        value={addr1_hint} onChange={ e => setAddr1_hint(e.target.value)} />
    </InputGrp>

    <UsersTable users={users} cb={cb} />


  </>)
}
