import React from 'react'
import {Socket} from 'socket.io-client'
import {Context} from './App'
import {UserType} from './caltypes'
import {fetch_status} from './fetchHelpers'
import {is_auth} from './utilrole'
import {FontAwesomeIcon as Fa} from '@fortawesome/react-fontawesome'
import {OffMenu} from './OffMenu'
import {Flags} from './App'
import {NotifyMsg, dts_now, dts_stamp, notify} from './helpers'
import './header.scss'
//
// icons here
//
const FasLeaf = ()=> <Fa icon={['fas', 'leaf']} className="fa-2x" />
const FasInfoCircle = ()=> <Fa icon={['fas', 'info-circle']} className="fa-2x" />
//const FasUserCheck = ()=> <Fa icon={['fas', 'user-check']} className="fa-2x" />
//const FasUserCircle = ()=> <Fa icon={['fas', 'user-circle']} className="fa-2x" />
const FasBars = ()=> <Fa icon={['fas', 'bars']} className="fa-2x" />
// const FasChevronCircleDown = ()=> <Fa icon={['fas', 'chevron-circle-down']} className="fa-lg" />
// const FasTimesCircle = ()=> <Fa icon={['fas', 'times-circle']} className="fa-lg" />


//
// exhaustvie ...のやつ
// メモ化してもだめなので?結局外に出した。
//
// async function fetch(socket: Socket, url: string){
//   //const res = await fetch_notifyme(`${url}/${socket.id}`)
//   const res = await fetch_status(`${url}`)
//   if(res.success === false){
//     console.log(`fetch_status fails. (${res.msg})`)
//   }
//   console.log('status@header', res)
// }

//notify typeは dt: new Date(), msg: 'aaaa' のように、来る。



//
// ●Header
//
export const Header = ({flags, setFlags, user, setUser}:{
  flags: Flags;
  setFlags: React.Dispatch<React.SetStateAction<Flags>>;
  user: UserType;
  setUser: React.Dispatch<React.SetStateAction<UserType>>;
}) : JSX.Element => {
  const {socket, urls} = React.useContext(Context)
  const [show, setShow] = React.useState(false) //これは Off Canvas Menuの show/hide に。
  const [{dts, msg}, setNotify] = React.useState<NotifyMsg>({dts: dts_now(), msg:''})
  //
  // ●subscribe notify だけど useCallbackはこのとおりうまくいくのだが、関数を外に出さないほうがいい。依存関係にも注意を払う。
  //
  // 0. kqは emit('notify', {dt: new Date(), msg}) として発信する。
  // 1. ここでは on('notify', {dt, msg}) で受け取る、が dtの内容は Dateの ISOString である。
  //     (通信経路で自動的にそうなっている)
  // 2. ここでは そのまま dt, msg で受けて、
  // 通常なら new Date(dt) として使うのだが、 通知ログは JSON文字列にして storageに書き込むので、...
  // 3. このまま 日付のISOStringを使うことにした。!! タグは dts とする。
  // したがって　通知storageまわりでは Dateにする必要がでてきたとき(date-fnsのformatでなど) new Date(dts)してから使う。
  const subscribe_on= React.useCallback(
    ()=>
      socket.on('notify', ({dt, msg}) => { //console.log('@@@notify', dt, msg)
        setNotify({dts: dt, msg})
        //notify({dts: dt, msg})//local storage notify test
        // ダイアログ？ちょっとうるさいな。
        //window.alert(`${dts_stamp(dts)}\n${msg}`)

      }),
      [socket]
  )
  const subscribe_off= React.useCallback(
    () =>
      socket.off('notify'),
      [socket]
  )
  //
  React.useEffect( ()=>{
    subscribe_on()
    return ()=>{subscribe_off()}
  }, [subscribe_on, subscribe_off])
  // (promiseを戻り値にしてはいけないので) asyncを定義してから、使う。useEffectの中で定義して。
  React.useEffect( ()=>{
    async function fetch(s: Socket, url: string){
      const res= await fetch_status(url)
      setUser(res.user)
    }
    fetch(socket, urls.status)
    return ()=> {}
  },[socket, setUser, urls.status])

  // ログイン部品を表示、非表示とする、同じタイミングでログインユーザー名を表示させてみる。
  // f6: login, f11: logout (仮)
  function login_or_logout(){
    if(is_auth(user)){
      // ログインしているのでそれを表示notifyしておく
      setNotify({dts: dts_now(), msg: `${user.username} ログイン中`})
      // ログインしているのでログアウトを表示/非表示トグルする、ログインは非表示とする。
      setFlags({...flags, fLogout: true, fLogin:false})
    }else{
      // ログインしていないが、notifyエリアはクリックで消せるようにしているのでここでは消さない。
      // ログインしていないのでログインを表示/非表示トグルする、ログアウトは非表示とする。
      setFlags({...flags, fLogout:false, fLogin:true })
    }
  }
  // Logioをトグル
  function login_user_info(){
    if( !is_auth(user) ) {setNotify({dts: dts_now(), msg: 'user情報なし' }); return}
    // addr1は長すぎるとというか末尾を切りたいけど住所詳細部を。。。どうするか。@@@
    const short1= user.addr1.replace(/[0-9]/g, '') //単純に数字を抜いておく。伏せてみる
    const short2= short1.replace(/[Ａ-Ｚａ-ｚ０-９]/g, '')
    const short3= short2.replace(/[ー－-]/g, '')
    // 全角での番地もあったので
    const msg=`ログイン中: ${user.username} ${user.mail} ${user.kname} ${short3}`
    setNotify({dts: dts_now(), msg })
  }
  //??
  // expanderについて デフォでは flex-grow はゼロであり、ひとつでも1のgrow要素があれば広がる。
  // const expander={flexGrow: 1}
  // expander内？では ellipsisが効かないので....
  //
  // sliceは ...start が列のインデックスの範囲よりも大きい場合は、空の配列が返されます なので注意。
  function ellipsis(msg: string){
    const limit= 56
    return ( msg.length < limit ) ? msg : (msg.slice(0, limit) + '...' )
  }
  //
  //
  //
  return(<>
  <header>

    {/* ロゴ */}
    <div className="logo" onClick={()=>notify({dts: dts_now(), msg: 'test logo clicked.'})}>
      <FasLeaf />
    </div>

    {/* 通知領域 */}
    <div className="expander" onClick={()=>setNotify({dts: dts_now(), msg:''})}>
     { 0<msg.length  && <>
        {dts_stamp(dts)}<br />
       {ellipsis(msg)}
       </>
     }
    </div>

    {/* ３段の高さを確認する。
    <a className="anchor" href="/" >
      <div className="a-text line-3" >
        <div>@@@</div>
        <div>@@@@</div>
        <div>@@@@@</div>
      </div>
    </a> */}

    {/* インフォメーション 今はログインについてのみ */}
    <button className="ghost" onClick={()=>login_user_info()}>
      <FasInfoCircle />
    </button>

     {/* ログインする、ログアウトする、のトグル */}
    {/* <button className="ghost" onClick={()=>login_or_logout()}>
      {is_auth(user)
        ? <FasUserCheck />
        : <FasUserCircle />
      }
    </button> */}
    {/* ログインorログアウトボタン */}
    {is_auth(user) 
      ? <button className="btn btn-outline-warning" onClick={()=>login_or_logout()}>ログアウト</button>
      : <button className="btn btn-outline-light" onClick={()=>login_or_logout()}>ログイン</button>
    }


      {/* オフラインメニューの表示アイコン */}
    <button className="bars" onClick={()=>setShow(true)}>
      <FasBars />
    </button>

  </header>

  <OffMenu show={show} setShow={setShow} flags={flags} setFlags={setFlags} user={user}/>

  </>)
}
