import {FontAwesomeIcon as Fa} from '@fortawesome/react-fontawesome'

//
// mailto anchor を生成して ブラウザからメールを起動する
//

//
// パスワード処理のため。(管理者が既存のパスワードを削除して新しいものを設定して通知するため)
//
// -------使い方
// <div>
// <Mailto label="パスワード再発行(mailto)"
//   to="mail@sasauchi.com"
//   subject="パスワードの件"
//   body={`
//   I forgot password issue new one.
//   パスワードの再発行を依頼します。
//   `}
// />
// </div>
//
type MailtoProps={
  label: string;
  to: string;
  cc?: string[];
  bcc?: string[];
  subject: string; // encodeが必要 windowsならなしで、できてしまうけどやはり必要
  body: string; //encodeが必要 改行などをちゃんとするため
}
//
// mailto docs
// https://developer.mozilla.org/en-US/docs/Learn/HTML/Introduction_to_HTML/Creating_hyperlinks#e-mail_links
// anchor hrefでの mailto の例
// <a href="mailto:nowhere@mozilla.org?subject=The%20subject%20of%20the%20email&body=The%20body%20of%20the%20email">
//
// hrefに設定すべき文字列を作る。
function mailto(opts: MailtoProps){
  const { to, cc, bcc, subject, body} = opts
  //const amp= '&amp;' // 項目のつなぎでencodeいる？なら。これはencode不要みたい。
  let s: string= `mailto:${to}?`
  if(cc) s += `cc=${cc.join(',')}&` //ひとりなら , カンマは出ないのでOK。 // @ はいいとおもうそのままで。
  if(bcc) s += `bcc=${bcc.join(',')}&`
  s += `subject=${encodeURIComponent(subject)}&`
  s += `body=${encodeURIComponent(body)}`
  //  
  return s
}
//
export const Mailto = (props: MailtoProps) =>{

  return (
  <div style={{border: "1px solid orangered"}}>
    Mailto: パスワードを忘れた場合に管理者にメールで再発行を依頼します
    <div className="input-group mb-1" >
      <a href={mailto(props)} className="btn btn-outline-primary">
        <Fa icon={['fas', 'envelope']} className="fa-2x" />
      </a>
      <div className="input-group-text">
          {props.label}
        </div>
    </div>
  </div>
  )

}
