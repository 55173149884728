import './ToggleSwitch.scss'

export const ToggleSwitch = ({flag, setFlag}:{
  flag:boolean;
  setFlag: (f:boolean) => void
}
): JSX.Element => {

  return(
    <label className="switch">
      <input type="checkbox" onChange={()=>setFlag(!flag)} checked={flag} />
      <span className="slider round"></span>
    </label>
  )

}