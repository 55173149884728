import React from 'react'
import {Context, Flags} from './App'
import {FontAwesomeIcon as Fa} from '@fortawesome/react-fontawesome'
//import {fetch_user, UserInfoType, initialUserInfo} from './fetchHelpers'
import {format, addMonths} from 'date-fns'

//
//
//
//
// ●Anchor
//
// const Anchor1 = ({url, dt}:{url:string; dt: Date;}) =>{
//   //const {fname, size, mtime} = item
//   const uri = encodeURI( `${url}/${format(dt, 'yyyy-MM-dd')}.xlsx`)
//   console.log(uri)

//   return(
//     <>
//       <a href={uri} download={"abc.xlsx"} target="_blank" rel="noreferrer"
//         className="btn btn-outline-primary btn-sm" role="button">
//         <Fa icon={['fas', 'file-download']} className="fa-2x" />
//        </a>
//        <span >
//        {' '}
//        </span>
//     </>
//   )
// }
//
// 
//
export const Report = ({flags, setFlags}:{
  flags: Flags;
  setFlags: React.Dispatch<React.SetStateAction<Flags>>;
  }) => {
  const [date, setDate] = React.useState<Date>(new Date())
  const {urls} = React.useContext(Context)
  const fmt= (dt:Date)=>format(dt, 'yyyy年MM月')
  //
  // ●Report fetch
  //
  // const fetch_report = async () =>{
  //   const HOST= 'zett.local'
  //   const PORT= 3002
  //   const stamp= () => format(dt, 'yyyy-MM-dd')
  //   const path_read = `http://${HOST}:${PORT}/api/report/${stamp()}.xlsx`
  //   const response= await fetch( path_read, {
  //     method: 'GET'
  //   })
  //   console.log(`@@@status ${response.status} `)
  //   console.log('@@@content-length', response.headers.get('content-length'))
  //   if(! response.body ){
  //     console.log(`@@@ error! nobody`)
  //     return <>err</>
  //   }
  //   const reader= response.body.getReader() // ブラウザAPI fetchでは getReader()が使える
  //   //
  //   // ... しかし ここまで、 ブラウザでは 独自のファイル化はできない。....
  //   //
  // }
  const clear = () => {
    setFlags({...flags, fReport: false})
  }
  // onChange
  const handleChange :React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    //console.log(`@@@`, e.target.value)
    switch(e.target.value){
      case '前月': setDate( addMonths(date, -1) ); break
      case '今月': setDate( date ); break
      case '来月': setDate( addMonths(date, 1) ); break
      default: break
    }

  }
  //
  // kq/routes2.ts で urlが /report/:date で受けている。 エクセルファイル名は現在の時刻で作成される、
  // ので 日付は kqで new Date(date) できる日付でわたす。
  // のだが、ブラウザがダウンロードするファイル名は変えられない!? ので　ファイル名として 2022-10-01.xlsx 
  // のようにレポートする日付をファイル名にする必要がある。
  // のだが、月が分かればよいので.... '2022-10.xlsx' --> kqで、 '2022-10-01' のようにして、 dbする。
  //
  return (<>

    <div className="input-group mb-1">
        <a href={`${urls.report}/${format(date, 'yyyy-MM')}.xlsx`} 
          download target="_blank" rel="noreferrer"
          className="btn btn-outline-primary btn-sm" role="button">
          <Fa icon={['fas', 'file-download']} className="fa-2x" />
       </a>

        <select className="form-select" value="今月" onChange={handleChange} aria-label="select report date">
          <option value="前月">{fmt(addMonths(date, -1))} 予約一覧ダウンロード</option>
          <option value="今月">{fmt(date)}予約一覧ダウンロード</option>
          <option value="来月">{fmt(addMonths(date, 1))}予約一覧ダウンロード</option>
        </select>  
      
      <button type="button" className="btn btn-outline-primary btn-sm" id="btn-report-clear"
        onClick={clear}>
        <Fa icon={['fas', 'times-circle']} className="fa-2x" />
      </button>

    </div>
  </>)

}
