import React from 'react'

//
// 〇EditNote
//
//[label (placeholder)] の入力。Changeごとに textを返すのだが、
// dayformで lines の loopごとに使うので 該当する line を 引数にもらって、callbackに返す。
// lineへの反映はdayformで callbackを作成して行う。
//
export type EditNoteProps ={
  prev: string; //編集する場合があるので現在のがあればここにもらう必要がある。
  label: string;
  placeholder: string;
  cb: (t: string) => void;
  readonly: boolean;
}
export const EditNote = ({prev, label, placeholder, cb, readonly}: EditNoteProps) =>{
  const [note, setNote] = React.useState<string>(prev)
  //
  const handle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNote(e.target.value)
    cb(e.target.value)
  }

  return(
    <div className="input-group">
      <label className="input-group-text">{label}</label>
      <input type="text" className="form-control" placeholder={placeholder}
        onChange={handle} value={note} readOnly={readonly} />
    </div>
  )
}
