import React from 'react'
import {Line} from '../utilline'
//
// ●2024 安置 A 向け 1,2,3,4日の葬儀期間の選択
// disabled={readonly || line.reason.indexOf('2日')<0} この部分は再考しよう。なぜdisable
//
export const SogiOption3 = ({line, readonly}: {line: Line; readonly: boolean;}) => {
  const [option, setOption] = React.useState(line.option)

  
  const handle = (e: React.ChangeEvent<HTMLInputElement>) => {
    line.option = e.target.value
    setOption(e.target.value)
  }
  //
  return (<>
  <div className="input-group">
    <label className="input-group-text">期間</label>

    {/* 1日 */}
    <label className="form-control input-group-text pointer">
      <input type="radio" className="form-check-input me-1"
        checked={option==='1日'} value={'1日'}
        disabled={readonly || false}
        onChange={handle}
      />
      <span className="ms-0">{'1日'}</span>
    </label>

    {/* 2日 */}
    <label className="form-control input-group-text pointer">
      <input type="radio" className="form-check-input me-1"
        checked={option==='2日'} value={'2日'}
        disabled={readonly}
        onChange={handle}
      />
      <span className="ms-0">{'2日'}</span>
    </label>

    {/* 3日 */}
    <label className="form-control input-group-text pointer">
      <input type="radio" className="form-check-input me-1"
        checked={option==='3日'} value={'3日'}
        disabled={ readonly}
        onChange={handle}
      />
      <span className="ms-0">{'3日'}</span>
    </label>

    {/* 4日 */}
    <label className="form-control input-group-text pointer">
      <input type="radio" className="form-check-input me-1"
        checked={option==='4日'} value={'4日'}
        disabled={readonly}
        onChange={handle}
      />
      <span className="ms-0">{'4日'}</span>
    </label>
  </div>

  </>)
}
