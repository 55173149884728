import {UserType, userNone} from './caltypes'
//
// common (login, logout, changepassword)
//
export type Res = {success: boolean; msg: string;}
export type Res2 = {success: boolean; msg: string; user: UserType}
export type Res3 = {success: boolean; msg: string; uu: UserType[]}



export type HinfoType = {
  url: string;
  host: string;
  origin: string;
  referer: string;
  'sec-fetch-site': string;
  'sec-fetch-mode': string;
  'sec-fetch-dest': string;
}
export type UserInfoType = {
  success: boolean;
  msg: string;
  user: UserType;
  hinfo: HinfoType;
  sess: string; // base64された　passportを含む
  sig: string;
  stamp: string; // この状態を確認したサーバーでの時刻
}
//const initialUser={ id: -1, username: '', memo: '' }
const initialHinfo = {
  url: '', host:'', origin: '', referer: '',
  'sec-fetch-site': '', 'sec-fetch-mode': '', 'sec-fetch-dest': ''
}
export const initialUserInfo: UserInfoType ={
  success: false, msg: '@init', user: userNone, hinfo: initialHinfo,  sess:'', sig:'', stamp:''
}
export async function fetch_user( url: string ): Promise<UserInfoType>{
  const response: Response= await fetch(
    url,
    {
      method: 'GET',
      mode: 'cors',
      headers:{
        'content-type': 'application/json; charset=UTF-8',
      },
      credentials: 'include', //●これでブラウザは認証情報の入ったリクエストを送ってくれるらしい。
    }
  )
  //
  if(response.ok){
    return await response.json()
  }else{
    return {...initialUserInfo, msg: 'NG: response fails'}
  }
}
//
// ●status
//
export async function fetch_status(url: string): Promise<Res2>{
  const response: Response= await fetch(
    url,
    {
      method: 'GET',
      mode: 'cors',
      headers:{
        'content-type': 'application/json; charset=UTF-8',
      },
      credentials: 'include', //●これでブラウザは認証情報の入ったリクエストを送ってくれるらしい。
    }
  )
  //
  if(response.ok){
    return await response.json()
  }else{
    return {success: false, msg: 'NG: response fails', user: userNone}
  }
}
//
// ●notifyme
//
export async function fetch_notifyme(url: string): Promise<Res>{
  const response: Response= await fetch(
    url,
    {
      method: 'GET',
      mode: 'cors',
      headers:{
        'content-type': 'application/json; charset=UTF-8',
      },
      credentials: 'include', //●これでブラウザは認証情報の入ったリクエストを送ってくれるらしい。
    }
  )
  //
  if(response.ok){
    return await response.json()
  }else{
    return {success: false, msg: 'NG: response fails'}
  }
}

//
// ●login
//
export type BodyLogin = {
  username: string; password: string; //この名前keyは passportで正確に必要。
  socket_id: string;
}
export async function fetch_login(url: string, opts: BodyLogin): Promise<Res2>{
  const response: Response= await fetch(
    url,
    {
      method: 'POST',
      mode: 'cors',
      headers:{
        'content-type': 'application/json; charset=UTF-8',
      },
      credentials: 'include', //●これでブラウザは認証情報の入ったリクエストを送ってくれるらしい。
      body: JSON.stringify(opts) 
    }
  )
  if(response.ok){
    return await response.json()
  }else{
    return {success: false, msg: 'NG: response fails', user: userNone}
  }
}
//
// ●logout
//
export type BodyLogout = {
//  username: string; password: string; //この名前keyは passportで正確に必要。
  socket_id: string;
}
export async function fetch_logout(url: string, opts: BodyLogout): Promise<Res>{
  const response: Response= await fetch(
    url,
    {
      method: 'POST',
      mode: 'cors',
      headers:{
        'content-type': 'application/json; charset=UTF-8',
      },
      credentials: 'include', //●これでブラウザは認証情報の入ったリクエストを送ってくれるらしい。
      body: JSON.stringify(opts)
    }
  )
  if(response.ok){
    return await response.json()
  }else{
    return {success: false, msg: 'NG: response fails'}
  }
}
//
// ●change password
//
export type BodyChangePassword = { //この名前keyは serverのroutesで定義した、それと同じ。
  username: string;
  password_current: string;
  password_new: string;
}
export async function fetch_changepassword(url: string, opts: BodyChangePassword): Promise<Res>{
  const response: Response= await fetch(
    url,
    {
      method: 'POST',
      mode: 'cors',
      headers:{
        'content-type': 'application/json; charset=UTF-8',
      },
      credentials: 'include', //●これでブラウザは認証情報の入ったリクエストを送ってくれるらしい。
      body: JSON.stringify(opts) 
    }
  )
  if(response.ok){
    return await response.json()
  }else{
    return {success: false, msg: 'NG: response fails'}
  }
}

//
// ●files (ダウンロードのlist)
//
// /files で戻る情報 see passport1 server
export type FolderItem=
{
  fname: string;
  size: number;
  mtime: string;//DateがISOフォーマットで!? 文字列化されてくる。JSONでは。
}
export type ResFiles = {
  success: boolean;
  msg: string;
  files: FolderItem[];
}
export async function fetch_files(url: string): Promise<ResFiles>{
  const response: Response= await fetch(
    url,
    {
      method: 'GET',
      mode: 'cors',
      headers:{
        'content-type': 'application/json; charset=UTF-8',
      },
      credentials: 'include', //●これでブラウザは認証情報の入ったリクエストを送ってくれるらしい。
      body: null
    }
  )
  if(response.ok){
    return await response.json()
  }else{
    return ({success: false, msg: 'NG: response fails', files:[]})
  }

}
//
// ●upload
//
type UploadOpts = {
  body: FormData;
}
export async function fetch_upload(url: string, opts: UploadOpts): Promise<Res>{
  const response: Response= await fetch(
    url,
    {
      method: 'POST',
      mode: 'cors',
      credentials: 'include', //●これでブラウザは認証情報の入ったリクエストを送ってくれるらしい。
      body: opts.body
    }
  )
  if(response.ok){
    return await response.json()
  }else{
    return {success: false, msg: 'NG: response fails'}
  }

}
//
// ●listusers
//
// (bodyに JSON入れる場合は headers/content-typeをちゃんとせよ、
//   省略したら kq/側でkoaがbodyからObjectを組み立てない!?)
type ListUsersOpts = {
  mode: string;
}
export async function fetch_listusers(url: string, opts: ListUsersOpts): Promise<Res3>{
  //console.log('@@@fetch_listusers', opts)
  //console.log(JSON.stringify(opts))
  const response: Response= await fetch(
    url,
    {
      method: 'POST',
      mode: 'cors',
      headers:{
        'content-type': 'application/json; charset=UTF-8',
      },
      credentials: 'include', //●これでブラウザは認証情報の入ったリクエストを送ってくれるらしい。
      body: JSON.stringify(opts)
    }
  )
  if(response.ok){
    return await response.json()
  }else{
    return {success: false, msg: 'NG: response fails', uu: []}
  }
}









// export function p_folderItem(item: FolderItem){
//   const {fname, size, mtime} = item // folderはまだ使わないかな。
//   //
//   const dt= Date.parse(mtime)
//   const s_mtime= format(dt, 'yyyy-MMdd HH:mm:ss')
//   const s= `${s_mtime} ${fname} (${size.toLocaleString()} bytes.)`
//   return s
// }
