import React, {ReactNode} from "react"
import {FontAwesomeIcon as Fa} from '@fortawesome/react-fontawesome'

//
// 適宜copyして変更して画面ごとに使うのだが、そのまま使う場合、あるいは、ひな形として置いておく、など。
//


//const FasTimesCircle = () => <Fa icon={['fas', 'times-circle']} className="fa-lg" />
export const FasChevronCircleUp = ()=><Fa icon={['fas', 'chevron-circle-up']} className="fa-lg" />
export const FasChevronCircleDown = ()=><Fa icon={['fas', 'chevron-circle-down']} className="fa-lg" />
export const FasTimesCircle = () => <Fa icon={['fas', 'times-circle']} className="fa-lg" />
export const FarEye = () => <Fa icon={['far', 'eye']} className="fa-lg" />
export const FarEyeSlash = () => <Fa icon={['far', 'eye-slash']} className="fa-lg" />


//
// misc parts
//
export const InputGrp = ({children}:{children:ReactNode}) => <div className="input-group">{children}</div>
export const FormFloating= ({children}:{children:ReactNode}) => <div className="form-floating mb-0">{children}</div>




//
// button
//
export const Btn = ({disabled = false, onClick, children}:{
  disabled?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  children: ReactNode;
}) => {
  return(
    <button
      type="button" className="btn btn-outline-primary"
      onClick={onClick} disabled={disabled} >
        {children}
    </button>
  )
}

//
// radio
//
export const Radio = ({value, checked, disabled, onChange}:{
  value: string;
  checked: boolean;
  disabled?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}) => {

  return(
    <label className="form-control input-group-text pointer">
      <input
        type="radio" className="form-check-input me-1"
        value={value} onChange={onChange}
        checked={checked} disabled={disabled}
      />
      <span className="ms-0">{value}</span>
    </label>
  )
}


//
// ●OpenArea
//
export const OpenDiv = ({open, label, onClick}: {
  open: boolean;
  label: string;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}) => {

  return (
    <div onClick={onClick} style={{ cursor: "pointer"}}>
      <span>{label}</span>{' '} {open
        ? <FasChevronCircleUp/>
        : <FasChevronCircleDown/>}
    </div>
  )
}


//
// checkbox + label で labelも含めた 全体でクリック可能に。
//
export const Checkbox = ({checked, label, disabled, onChange}:{
  checked: boolean;
  label: string;
  disabled?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}) => {

  return(
    <label className="form-control input-group-text pointer">
      <input
        type="checkbox" className="ms-0"
        checked={checked} onChange={onChange} />
      <span
        className="mx-3">{label}</span>
    </label>
  )
}
//
// input-group-text
//
export const Text= ({children}:{
  children: ReactNode;
}) => {
  return(
    <div className="input-group-text form-control">
      {children}
    </div>
  )
}

//
// input text
//
export const InputText = ({value, placeholder, onChange}:{
  value: string;
  placeholder: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}) => {

  return(
    <input
      type="text" className="form-control" placeholder={placeholder}
      value={value} onChange={onChange} >
    </input>
  )
}
//
// input text with Label    電話: 01-1234-6789
//
export const InputTextLabel = ({label, value, placeholder, onChange}:{
  label: string;
  value: string;
  placeholder: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}) => {

  return(<>
    <label
      className="input-group-text">{label}</label>
    <input
      type="text" className="form-control" placeholder={placeholder}
      value={value} onChange={onChange} />
  </>)
}

//
// div text style Linen (input-group-textなくても?)
//
export const TextWarn= ({children}:{
  children: ReactNode;
}) => {
  return(
    <div style={{ backgroundColor: "Linen", fontSize: "0.9rem"}}>
      {children}
    </div>
  )
}
//
//form-floating をつかう text area をまとめておく
//(placeholderはダミーが必要)
//
export const TextArea= ({value, label, onChange}:{
  value: string;
  label: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
//  children: ReactNode;
})=>{

  return(
    <div className="form-floating mb-0">
      <textarea
        className="form-control" placeholder="@"
        value={value} onChange={onChange} />
      <label>{label}</label>
    </div>

  )

}
