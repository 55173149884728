import React from 'react'

//
// Btn 普通の アイコンボタンに使う
//
export type BtnProps = {
  handle: ()=>void;
  enable?: boolean;
  children: React.ReactNode;
}
export const Btn = ({handle, enable = true, children}: BtnProps) => (
  <button
    type="button"
    className="btn btn-outline-primary" 
    onClick={handle}
    disabled={!enable}
  >
  {children}
  </button>
)

//
// BtnA ハンドルが (fetch...のように) 非同期なもの、LoginとかLogoutの。
//
export type BtnAProps = {
  handle: ()=>Promise<void>;
  enable?: boolean;
  children: React.ReactNode;
}
export const BtnA = ({handle, enable = true, children}: BtnAProps) => (
  <button
    type="button"
    className="btn btn-outline-primary" 
    onClick={handle}
    disabled={!enable}
  >
  {children}
  </button>
)
