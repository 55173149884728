import React from 'react'
import {Line} from '../utilline'


// 電話番号かメールアドレスか。
// 簡単に。reするとしたら。 /@/.test(memo) でメール, /^[0-9-]+$/で 電話。
// 

const tes_phone= (text: string) => /^[0-9-]+$/.test(text)
const tes_mail= (text: string) => /@/.test(text)

export const PhoneOrMail = ({line}: {line: Line}) => {
  const [phone, setPhone] = React.useState<boolean>(tes_phone(line.row.memo))
  const [mail, setMail] = React.useState<boolean>(tes_mail(line.row.memo))
  const [memo, setMemo] = React.useState(line.row.memo)
  //
  //
  //
  const Radio = ({label, b, handle}:{
    label: string, b: boolean; handle: (e: React.ChangeEvent<HTMLInputElement>) => void;}) => {
      
    return(
      <label className="form-control input-group-text pointer">
        <input type="radio" className="form-check-input me-1"
          checked={b} value={label}
          onChange={handle}
        />
        <span className="ms-0">{label}</span>
      </label>
    )
  }

  //
  // 
  //
  const handle = (e: React.ChangeEvent<HTMLInputElement>) => {
    //console.log(`@@@PhoneOrMail ${e.target.value}`)
    setPhone( e.target.value === '電話')
    setMail(e.target.value === 'メール')
    //いずれにせよ edit部はクリアしたい、placeholderをみせるので。
    setMemo('')
    line.row.memo=''
  }

  //
  // memoを設定する input 向けのハンドル
  // すぐにlineに反映させているのだが、useStateしておかないとそしてこのhandleEditしないと編集できない。
  //
  const handleEdit =  (e: React.ChangeEvent<HTMLInputElement>) => {
    setMemo(e.target.value)
    line.row.memo=e.target.value
  }
  //
  //
  //
  return (<>
    {/* hoyo_atの３択 */}
    <div className="input-group">
      <span className="input-group-text">予約の確認先</span>
      <Radio label={'電話'} b={phone} handle={handle} />
      <Radio label={'メール'} b={mail} handle={handle} />
    </div>

    {/* hoyo_addr を atに1:1対応させる,られる?。 @2022-1027 */}

    { phone &&
    <input type="text"
      className="form-control"
      value={memo}
      onChange={handleEdit}
      placeholder="電話番号をご記入ください"
    />
    }

    { mail &&
    <input type="text"
      className="form-control"
      value={memo}
      onChange={handleEdit}
      placeholder="メールアドレスをご記入ください"
    />
    }
  </>)
}
