import React from 'react'
import {Line} from '../utilline'
//
// ●2024 ホール向け 1日葬儀, 2日葬儀, 1日法事 の選択
//
// disabledの選択は ? だ。 readonly なら さわらせない のだが line.reason をみて判断て、以前のはなぜ？
// ●要注意↑
//
//
export const SogiOption2 = ({line, readonly}: {line: Line; readonly: boolean;}) => {
  const [option, setOption] = React.useState(line.option )
 
  const handle = (e: React.ChangeEvent<HTMLInputElement>) => {
    line.option = e.target.value
    setOption(e.target.value)
    //console.log('@@@SogiOption2', line.option)
  }
  //
  return (<>
  <div className="input-group">
    <label className="input-group-text">葬儀/法事</label>

    {/* 1日葬儀 */}
    <label className="form-control input-group-text pointer">
      <input type="radio" className="form-check-input me-1"
        checked={option==='1日葬儀'} value={'1日葬儀'}
        disabled={readonly}
        onChange={handle}
      />
      <span className="ms-0">{'1日葬儀'}</span>
    </label>

    {/* 2日葬儀 */}
    <label className="form-control input-group-text pointer">
      <input type="radio" className="form-check-input me-1"
        checked={option==='2日葬儀'} value={'2日葬儀'}
        disabled={readonly}
        onChange={handle}
      />
      <span className="ms-0">{'2日葬儀'}</span>
    </label>

    {/* 1日法事 */}
    <label className="form-control input-group-text pointer">
      <input type="radio" className="form-check-input me-1"
        checked={option==='1日法事'} value={'1日法事'}
        disabled={readonly}
        onChange={handle}
      />
      <span className="ms-0">{'1日法事'}</span>
    </label>
  </div>

  </>)
}
