import React from 'react'

//
// ●CheckNote
//
export type CheckNoteProps ={
  init: string;
  label: string;
  placeholder: string;
  cb: (t: string) => void;
}
export const CheckNote = ({init, label, placeholder, cb}: CheckNoteProps) =>{
  const [checked, setChecked] = React.useState<boolean>(init==='' ? false : true)
  const [note, setNote] = React.useState<string>(init)
  //  
  const hCheck= (e: React.ChangeEvent<HTMLInputElement>) =>{
    //console.log('@@@value', e.target.value) // "on" これはいつも onだな
    //console.log('@@@checked', e.target.checked) // true ここは prevで反応してるので初期値だけ気を付ければいいか。
    setChecked( (prev) => !prev )
    if(e.target.checked === false){
      setNote('')//(クリアするとplaceholderが見えるので便利)
      cb('') // これも必要のはず。
    }
  }
  //
  const hChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNote(e.target.value)
    cb(e.target.value)
  }
  //
  // input-groupを２つにして分けると２行に。　一つで挟むと、横に input 欄ができる。今は２行で。
  //
  return (<>
    <div className="input-group">

      <label className="input-group-text pointer">
        <input type="checkbox" className="form-check-input pointer"
          checked={checked}
          onChange={hCheck}
        />
        <span className="mx-3">{label}</span>
      </label>
    
    </div>
    <div className="input-group">

      { checked &&
        <input type="text" className="form-control ellipsis"
          value={note}
          onChange={hChange}
          placeholder={placeholder}
        />
      }
    </div>
  </>)
}
