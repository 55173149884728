import React from 'react'
import {Line} from '../utilline'
//
// ●2024 安置 A 向け 葬儀の予定 メモ扱いだけど
//  2日葬 1日葬 直葬 未定 --> funeral_memo 新設に書くので option 扱いではない。
//
//
export const AntiOption = ({line, readonly}: {line: Line; readonly: boolean;}) => {
  const [option, setOption] = React.useState( line.row.funeral_memo=== '' ? '2日葬儀': line.row.funeral_memo )

  
  const handle = (e: React.ChangeEvent<HTMLInputElement>) => {
    line.row.funeral_memo = e.target.value //これ書き戻せたっけ？
    setOption(e.target.value)
  }
  //
  return (<>
  <div className="input-group">
    <label className="input-group-text">葬儀の予定</label>

    {/* 2日葬 */}
    <label className="form-control input-group-text pointer">
      <input type="radio" className="form-check-input me-1"
        checked={option==='2日葬'} value={'2日葬'}
        disabled={readonly || false}
        onChange={handle}
      />
      <span className="ms-0">{'2日葬'}</span>
    </label>

    {/* 1日葬 */}
    <label className="form-control input-group-text pointer">
      <input type="radio" className="form-check-input me-1"
        checked={option==='1日葬'} value={'1日葬'}
        disabled={readonly}
        onChange={handle}
      />
      <span className="ms-0">{'1日葬'}</span>
    </label>

    {/* 直葬 */}
    <label className="form-control input-group-text pointer">
      <input type="radio" className="form-check-input me-1"
        checked={option==='直葬'} value={'直葬'}
        disabled={ readonly}
        onChange={handle}
      />
      <span className="ms-0">{'直葬'}</span>
    </label>

    {/* 未定 */}
    <label className="form-control input-group-text pointer">
      <input type="radio" className="form-check-input me-1"
        checked={option==='未定'} value={'未定'}
        disabled={readonly}
        onChange={handle}
      />
      <span className="ms-0">{'未定'}</span>
    </label>
  </div>

  </>)
}
