import React from 'react'
import {Line} from '../utilline'
//
// ●葬儀を延長するための line.opition の処理
// 編集を許すので in は line.row.link をみるしかない。line.optionは dbにはかかれない制約なので。
// out は line.optionで、ペア予約されるが line.optionそのものは tuya レコード(db)には、存在しない。sigh...
//
export const SogiOption = ({line, readonly}: {line: Line; readonly: boolean;}) => {
  const [option, setOption] = React.useState(line.option)
  // クリックすると line.optionは 二日、など設定されるので判定には注意。
 
  // 互換？
  //console.log(`@@@SogiOption line.row.link='${line.row.link}'`)

  const handle = (e: React.ChangeEvent<HTMLInputElement>) => {
    line.option = e.target.value
    setOption(e.target.value)
  }
  //
  return (<>
  <div className="input-group">
    <label className="input-group-text">葬儀期間</label>

    {/* 二日 */}
    <label className="form-control input-group-text pointer">
      <input type="radio" className="form-check-input me-1"
        checked={option==='二日'} value={'二日'}
        disabled={readonly || false}
        onChange={handle}
      />
      <span className="ms-0">{'二日'}</span>
    </label>

    {/* 三日 */}
    <label className="form-control input-group-text pointer">
      <input type="radio" className="form-check-input me-1"
        checked={option==='三日'} value={'三日'}
        disabled={ readonly || line.reason.indexOf('三日')<0}
        onChange={handle}
      />
      <span className="ms-0">{'三日'}</span>
    </label>

    {/* 四日 */}
    <label className="form-control input-group-text pointer">
      <input type="radio" className="form-check-input me-1"
        checked={option==='四日'} value={'四日'}
        disabled={readonly || line.reason.indexOf('四日')<0}
        onChange={handle}
      />
      <span className="ms-0">{'四日'}</span>
    </label>
  </div>

  </>)
}
