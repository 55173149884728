import React from "react"
import {UserType} from '../caltypes'
import '../userinfo.scss'

//
// Btn 普通の アイコンボタンに使う
//
export type BtnProps = {
  handle: ()=>void;
  enable?: boolean;
  children: React.ReactNode;
}
export const Btn = ({handle, enable = true, children}: BtnProps) => (
  <button
    type="button"
    className="btn btn-outline-primary" 
    onClick={handle}
    disabled={!enable}
  >
  {children}
  </button>
)

//
// ユーザー一人分の表示をする Table body
// (widthに emや vw などの単位は使えない)
// 幅を調整するとき...
//             <td width={'24px'}>{n3(u.id)}</td>
//
const Tbody = ({uu, cb}:{
  uu: UserType[];
  cb: (u:UserType) => void;
}) => {

  return (
  <tbody className="userinfo">

  {
    uu.map( u => (
      <tr key={u.id}>
        <td>
          <button type="button" className="btn btn-outline-primary"  onClick={()=>cb(u)} >
            {u.username}
          </button>
        </td>
        <td width={'84px'}>{u.kname}</td>
        <td>{u.addr1}</td>
      </tr>
    ))
  }
      
  </tbody>)


}

export function UsersTable({users, cb}:{
  users: UserType[];
  cb: (u: UserType) => void;
}) : JSX.Element {
//const n3 = (n: number) => String(n).padStart(2, '0')

if(users.length===0) return (<div>(ここに検索結果のユーザー情報が表示されます)</div>)

return (
  <table>
    <thead className="head">
    
    <tr key={users.length}>
      <td>ログインID</td>
      <td>漢字名</td>
      <td>住所1</td>
    </tr>
  
    </thead>

    <Tbody uu={users} cb={cb} />
  </table>
)

}

