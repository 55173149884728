import React from 'react'
import {FontAwesomeIcon as Fa} from '@fortawesome/react-fontawesome'

const FarEye = () => <Fa icon={['far', 'eye']} className="fa-lg" />
const FarEyeSlash = () => <Fa icon={['far', 'eye-slash']} className="fa-lg" />

//
// Email type emailの入力
//
export type EmailProps = {
  id: string;
  handle: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  label: string;
}
export const Email = ({id, handle, value, label}: EmailProps) => (

  <div className="form-floating mb-1">
    <input
      id={id}
      type="email"
      className="form-control"
      onChange={handle}
      value={value}
      placeholder="@"
    />
    <label htmlFor={id}>{label}</label>
  </div>

)
//
// Password 入力 type passwordと text を切り替える処理がつく
//
export type PasswordProps = {
  id: string;
  handle: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  label: string;
}
export const Password = ({id, handle, value, label}: PasswordProps) => {
  const [isRevealPassword, setIsRevealPassword] = React.useState(false)

  const togglePassword= ()=>{
    setIsRevealPassword( (prevState) => !prevState)
  }

  return(
  <div className="form-floating mb-1">
    <input
      id={id}
      type={isRevealPassword ? 'text': 'password'}
      className="form-control"
      onChange={handle} 
      value={value}
      placeholder="@"
     />
    <label htmlFor={id}>{label}</label>
    <span
      className="password-reveal pointer"
      onClick={togglePassword}
    >
     {isRevealPassword
      ? <FarEye />
      : <FarEyeSlash />
      }
    </span>
  </div>)
}

//
// textarea parts (form-floating で TextAreaの場合 placeholderは???)
// form-controlなので colsは無意味かな。
// placeholderをつけないと form-floating にならないのはなぜ、内容とは関係なく labelが 
//
export type TextAreaProps = {
  rows: number;
  label: string;
  cb: (t: string) => void;
}
export const TextArea = ({rows, label, cb}: TextAreaProps) => {
  const [text, setText] = React.useState('')
  //
  const handle = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.currentTarget.value)
    cb(e.currentTarget.value)
  }

  return(
    
    <div className="form-floating mb-1">
      <textarea rows={rows} onChange={handle}
        className="form-control"  value={text} placeholder=""/>
      <label>{label}</label>
    </div>
    
  )
}

//
// Check1
//
//
export type Check1Props = {
  label: string;
  cb: (b: boolean) =>void;
}
export const Check1 = ({label, cb}: Check1Props)=>{
  const [checked, setChecked] = React.useState<boolean>(false)
  //
  const handle= (e: React.ChangeEvent<HTMLInputElement>) =>{ 
    setChecked( (prev) => !prev )
    cb(e.currentTarget.checked)
    //console.log('@@@Check1', e.currentTarget.checked)
  }
  //
  return(
    <div className="list-group-item">
      <input
        type="checkbox"
        onChange={handle}
        checked={checked}>
      </input>
      <span className="mx-3">{label}</span>
    </div>

  )
}
