import React from 'react'
import {FontAwesomeIcon as Fa} from '@fortawesome/react-fontawesome'
import {Context} from './App'
import {UserType} from './caltypes'
import {fetch_changepassword, BodyChangePassword} from './fetchHelpers'
import {is_auth} from './utilrole'
//
// ●ChangePW (ログインユーザーがパスワードを変更する)
//
export const ChangePW = ({user}: {
  user:UserType
  } ) :JSX.Element => {
  const {urls} = React.useContext(Context)
  const [current, setCurrent] = React.useState('')
  const [newone, setNewone] = React.useState('')
  const [msg, setMsg] = React.useState('パスワード変更を実行します')
  const [{err1, err2}, setErrors] = React.useState({ err1: '', err2: '' })
  const [enabledChangePW, setEnabledChangePW] = React.useState(false)
  const [isRevealPassword, setIsRevealPassword] = React.useState(false)
  //const [open, setOpen] = React.useState(false)
  // クリアするのに必要なrefs (cur, new)
  const inputRef1 = React.useRef<HTMLInputElement>(null)
  const inputRef2 = React.useRef<HTMLInputElement>(null)
  //
  // enabledについては 入力内容による。userがログインしていないときは全部だめ、としなければ。
  //
  // React.useEffect( () => {
  //   //setMsg(who(user))
  //   setEnabledChangePW(is_auth(user))
  //   return ()=>{}
  // }, [user])

  //
  // uty
  //
  //const log= (s: string) => socket.emit('log', {msg: s})
  function test(s: string, min: number, max: number): boolean {
    return ( min <= s.length && s.length <= max )
  }

  //
  // ●パスワードを入力 current, newone
  //
  const onChangeCurrent = (e: React.ChangeEvent<HTMLInputElement>)=>{
    setCurrent(e.currentTarget.value)
    const ok_current = test(e.currentTarget.value, 3, 32)
    const ok_newone = test(newone, 3, 32)
    setErrors( (prev) => ({...prev, err1: ok_current?'':'３文字以上32文字以下'}) )
    setEnabledChangePW( ok_current && ok_newone )
  }
  const onChangeNewone = (e: React.ChangeEvent<HTMLInputElement>)=>{
    setNewone(e.currentTarget.value)
    const ok_newone = test(e.currentTarget.value, 3, 32)
    const ok_current = test(current, 3, 32)
    setErrors( (prev) => ({...prev, err2: ok_newone?'':'３文字以上32文字以下'}) )
    setEnabledChangePW( ok_newone && ok_current )
  }
  //
  // ●toggle reveal
  //
  const togglePassword= ()=>{
    setIsRevealPassword( (prevState) => !prevState)
  }

  //
  // ●パスワード変更実行
  //
  const handleChangePW = async () =>{
    if(current.length === 0){
      setErrors( (prev) => ({...prev, err1:'current password empty'}))
      setEnabledChangePW(false)
      return
    }
    if(newone.length === 0){
      setErrors( (prev) => ({...prev, err2:'new password empty'}))
      setEnabledChangePW(false)
      return
    }
    const body: BodyChangePassword= {
      username: user.username,
      password_current: current,
      password_new: newone
    }
    const url= `${urls.changepassword}` // without notify
    const res = await fetch_changepassword(url, body)
    if(res.success){
      setMsg(`パスワードは正しく変更されました`)
    }else{
      setMsg(res.msg)
    }
    //log(res.msg)
    
    //
    // ユーザー名パスワードの入力をクリアしておく
    // (さすがに ref 先は確認が必要だ。)
    setCurrent('')
    setNewone('')
    if(inputRef1?.current?.value){
      inputRef1.current.value= ''
    }
    if(inputRef2?.current?.value){
      inputRef2.current.value= ''
    }
    setEnabledChangePW(false) //クリアしたら...当然だからここは不要か。いや必要。
  }

  if(!is_auth(user)){
    return (<div style={{border: "1px solid darkgrey"}}>
      パスワード変更にはログインが必要です。
    </div>)
  }

  //
  // render
  //
  return(
    <div style={{border: "1px solid darkgrey"}}>
    <div>ユーザー ({user.username}) のパスワードを変更します</div>

    <div className="form-floating mb-1">
      <input type={isRevealPassword ? 'text': 'password'} onChange={onChangeCurrent} ref={inputRef1}
        className="form-control" id="cur" placeholder="password" />
      <label htmlFor="cur">Password (現行)</label>
      <span onClick={togglePassword}  className="password-reveal" >
        {isRevealPassword
          ? <Fa icon={['far', 'eye']} className="fa-lg" />
          : <Fa icon={['far', 'eye-slash']} className="fa-lg" />
        }
      </span>

    </div>
    {0<err1.length ? <p>{err1}</p> : <></>}

    <div className="form-floating mb-1">
      <input type={isRevealPassword ? 'text': 'password'} onChange={onChangeNewone} ref={inputRef2}
        className="form-control" id="new" placeholder="password" />
      <label htmlFor="new">Password (新規)</label>
      <span onClick={togglePassword}  className="password-reveal" >
        {isRevealPassword
          ? <Fa icon={['far', 'eye']} className="fa-lg" />
          : <Fa icon={['far', 'eye-slash']} className="fa-lg" />
        }
      </span>
    </div>
    {0<err2.length ? <p>{err2}</p> : <></>}
    
    <div className="input-group mb-1">
      <button type="button" className="btn btn-outline-primary" id="btn-login"
      
      onClick={handleChangePW} disabled={!enabledChangePW} >
        <Fa icon={['fas', 'user']} className="fa-lg" />
        {' '}Change Password
      </button>

      <div className="input-group-text">
        {msg}
      </div>
    </div>
  </div>
  )
}