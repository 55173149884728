import React from 'react'
import {Line} from '../utilline'

//
// 〇HoyoAt
//
// hoyo_ 項目は nenki, misc, at, owner, addr, phone 6つ。以下入力と３択あり。
//
// nenkiは checkして、入力
// □ 四十九日、一周忌、３回忌、七回忌　十三回忌　十七回忌　などをご記入ください
//
// miscは checkして、入力
// □墓閉まい、仏壇開眼などをご記入ください
//
// at 自宅/金蓮寺/その他 の３択   <---ここ
// owner 施主名入力
// addr お参りする場所を入力
// phone 施主電話番号
//
//
// ---- onChange は サブ部品を作って渡すとなると、いろいろ複雑になりすぎるので、
// ---- ここのように ３択、ひとつ Edit付き、のような場合は 書き下ろしてまとめたほうが良い。
// ---- ハンドラの不要な部分で classNameがうるさくなりそうなら サブするほうが良い。
// ----> いや handleをちゃんとした型でつくればサブ部品にできたかな。
// ---- とにかく inputの "radio" での、checked, valueの理解を確かにしないと....
//
// 0. (e: React.ChangeEvent<HTMLInputElement>)=>{} は必要か？
// 1. input type radio 要素では 誰が? 排他をやってくれているのか？
// 2. input type radio でも valueをセットすると e.target.value はその値になる。
//     eventは "on" で飛んでくるが valueをセットすると "自宅"　のように飛んでくるので、便利。
//

//デフォルト設定のため、初期レコードでは空なので。
// @2022-1110 デフォは自宅に。
function whatTextAt(at: string){
  // if( at === '自宅' || at === '金蓮寺' ) return at
  // else return 'その他'
  if( at === '' || at === '自宅' ){
    return '自宅' //デフォ変更
   }
   else if( at === '金蓮寺' || at === 'その他'){
    return at
   }
  else {
    console.log(`@ whatTextAt 初期値エラー ${at}`)
    return '自宅'
  }
}
// at/addr 1:1 対応とできたので...@2022-1027
// function whatEditedAt(at: string){
//   if( at === '自宅' || at === '金蓮寺' ) return ''
//   else return at
// }
//
// 法要室葬儀ができたので念のため？法要の編集時に場所の変更はできないようにしよう。会食とかキャンセルとか不具合
// の可能性もあるし、編集ではメール飛ばないし、今のところは。...
// @2022-1110 場所の変更はよくあるらしく檀家様に取り直しを求めるのは？なので編集させる。
// 予約変更メールも出すので、時期的に衝突することはレアなので変更を許可する。この時点で可能とする前提で。。。強制的に。
// 同様の理由で会食も。いいのかな。
export const HoyoAt = ({line, editmode}: {line: Line; editmode: boolean;}) => {
  const [textAt, setTextAt] = React.useState(whatTextAt(line.row.hoyo_at))
  //const [editedAt, setEditedAt] = React.useState(whatEditedAt(line.row.hoyo_at))
  const [addr, setAddr] = React.useState(line.row.hoyo_addr)
  //
  //  (ここでは textAt を参照して判断が必要なので 部品化はできるが 外へは出せない。)
  //
  const Radio = ({t, handle}:{
    t: string; handle: (e: React.ChangeEvent<HTMLInputElement>) => void;}) => {
      
    return(
      <label className="form-control input-group-text pointer">
        <input type="radio" className="form-check-input me-1"
          checked={textAt===t} value={t}
          onChange={handle}
          disabled={false}
        />
        <span className="ms-0">{t}</span>
      </label>
    )
  }
  // addr向け
  const Radio2 = ({t, handle}:{
    t: string; handle: (e: React.ChangeEvent<HTMLInputElement>) => void;}) => {
      
    return(
      <label className="form-control input-group-text pointer">
        <input type="radio" className="form-check-input me-1"
          checked={addr===t} value={t}
          onChange={handle}
          disabled={false}
        />
        <span className="ms-0">{t}</span>
      </label>
    )
  }

  //
  // textAtを設定するradio向けハンドル
  //
  const handle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextAt(e.target.value)
    line.row.hoyo_at = e.target.value
    line.row.facility = e.target.value === '金蓮寺' ? '法要室' : '' //@2022-1027,拡張にて。
    
    // addrと連携する場合があるのでクリアも必要。onChangeなので。
    // ただし at金蓮寺 では デフォ が必要 sigh...
    if(e.target.value==='金蓮寺'){
      setAddr('法要のみ')
      line.row.hoyo_addr= '法要のみ' 
    }else{
      setAddr('')
      line.row.hoyo_addr= '' 
    }


    // if(e.target.value === 'その他') {
    //   /* editが開いているはず そっちで*/
    // }else {
    //   setEditedAt('') // その他、の内容はクリアする 
    // }
  }
  //
  // addrを設定するradio向けのハンドル 金蓮寺の場合は２択。かつこの内容は addrに書く。
  //
  const handle2= (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextAt('金蓮寺')
    setAddr(e.target.value)
    line.row.hoyo_addr=e.target.value
  }

  //
  // addrを設定する textarea 向けのハンドル
  //
  const handleEdit =  (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAddr(e.target.value)
    line.row.hoyo_addr=e.target.value
  }
  //
  return (<>
    {/* hoyo_atの３択 */}
    <div className="input-group">
      <Radio t={'自宅'} handle={handle} />
      <Radio t={'金蓮寺'} handle={handle} />
      <Radio t={'その他'} handle={handle} />
    </div>

    {/* hoyo_addr を atに1:1対応させる,られる?。 @2022-1027 */}

    { textAt === '自宅' &&
    <textarea
      className="form-control"
      value={addr}
      onChange={handleEdit}
      placeholder="自宅住所に変更があればご記入ください"
    />
    }

    { textAt === 'その他' &&
    <textarea
      className="form-control"
      value={addr}
      onChange={handleEdit}
      placeholder="お参りする場所をご記入ください"
    />
    }

    { textAt === '金蓮寺' && <>
    <div className="input-group">
      <div className="input-group-text">会食の有無</div>
    </div>
    <div className="input-group">
        <Radio2 t={'法要のみ'} handle={handle2} />
    </div>
    <div className="input-group" >
        <Radio2 t={'法要後寺内にて会食希望'} handle={handle2} />
    </div>

    </>}
    



  {/* <div>hoyo_at:{line.row.hoyo_at}, textAt: {textAt}, edited:{editedAt}</div> */}

  </>)
}
