import React from 'react'
import {io, Socket} from 'socket.io-client'
import {Calendar} from './calendar'
import {Header} from './Header'
import {Mailto} from './Mailto'
import {Login} from './Login'
import {Logout} from './Logout'
import {ChangePW} from './ChangePW'
import {Report} from './Report'
import {UserType, userNone} from './caltypes'
import {is_auth} from './utilrole'
import {EditUserFull} from './EditUserFull'
//
// ● .env から得るもの。
//
// API_ROOT
//
const API_ROOT: string= process.env.REACT_APP_API_ROOT || `${document.location.host}/api`
//
// IO_URL
//
const io_server_url: string = process.env.REACT_APP_IO_URL || document.location.host
//
// MailTo関連
//
const MAIL_LABEL: string= process.env.REACT_APP_MAIL_LABEL || 'Reissue password'
const MAIL_TO: string= process.env.REACT_APP_MAIL_TO || 'mail@sasauchi.com'
const MAIL_SUBJECT: string= process.env.REACT_APP_MAIL_SUBJECT || 'Regarding password '
const MAIL_BODY: string= process.env.REACT_APP_MAIL_BODY || 'I forgot password, please reissue it.'
//
// ●CONTEXTを作成し提供する
//
// コンテキストの内容は App ロード時に決まるもの、が良い。状態変化しないものを参照する、ということで、
// ロード時にまず接続する socket と 環境変数から(ロード時に)決まる fetch api の URLs である。
// (いちいちプロパティ経由にしないので便利)
//
type SocketContext = {
  socket: Socket;
  urls: {user: string; status: string, /*notifyme: string;*/ login: string; logout: string;
    upload: string; download: string; files: string; changepassword: string;
    listusers: string; report: string;}
}
export const Context = React.createContext<SocketContext>({
  socket: io(io_server_url),
  urls: {
    user: `${API_ROOT}/user`,
    status: `${API_ROOT}/status`,
    login: `${API_ROOT}/login`,
    logout: `${API_ROOT}/logout`,
    upload: `${API_ROOT}/upload`,
    download: `${API_ROOT}/download`,
    files: `${API_ROOT}/files`,
    changepassword: `${API_ROOT}/changepassword`,
    listusers: `${API_ROOT}/listusers`,
    report: `${API_ROOT}/report`
  }
})
//
// ●Flagsについて
// Header/Offcanvas menuで設定、変更される情報
// Offcanvas menu自体の show/hide は Headerでおこない、
// Globalなこの Flags 情報はここAppから配る。
//
export
type Flags = {
  fLogio: boolean;
  fUserInfo: boolean;
  fTesicon: boolean;
  fTesio: boolean;
  fChangePW: boolean;
  fLogin: boolean;
  fUpFile: boolean;
  fDnFile: boolean;
  fAdminTool: boolean;
  fMailTo: boolean;
  fLogout: boolean;
  fCalendar: boolean;
  fReport: boolean;
  fDebug: boolean; //制約デバッグのため@2022-1025
  fDebug2: boolean;
  fAdminEdit: boolean;
  fEditUserFull: boolean;
  fAddUserFull: boolean;
}
//
// socketのclose()を Appの useEffect戻り関数でおこなっていたけど...
// Globalな Flagsをいれたのでそしてここから配るので、flagが変更されたら
// Appは mount/unmount される。ことになるので、socketの(明示的な自分での)closeはやめる。!?
//
//  ●App
//
const App = (): JSX.Element => {
  const {socket, urls} = React.useContext(Context)
  const [flags, setFlags] = React.useState({
    fLogio: false, //@@@
    fUserInfo: false,
    fTesicon: false,
    fTesio: false,
    fChangePW: false,
    fLogin: false,
    fUpFile: false,
    fDnFile: false,
    fAdminTool: false,
    fMailTo: false,
    fLogout: false,
    fCalendar: true,
    fReport: false,
    fDebug: false,
    fDebug2: false,
    fAdminEdit: false,
    fEditUserFull: false,
    fAddUserFull: false,
  })
  const [user, setUser] = React.useState<UserType>(userNone)
  //
  // debug 確認用
  //
  // React.useEffect( () => {
  //   console.log('App mount.')
  //   return ()=>{console.log('App unmount.')}
  // }, [])
  const WaitingLogin = () => (<div>
    <span className="spinner-border text-info" role="status" ></span>
    <span>&nbsp;ログインボタンを押してください。(カレンダーはログイン後に表示されます)</span>
  </div>)
  //
  // 〇App renderではフラグやログイン状態により、パーツの表示非表示を行う。
  //
  return (
  <Context.Provider value={{socket, urls}}>
  <div className="container-fluid" style={{padding: 0}}>
    <Header flags={flags} setFlags={setFlags} user={user} setUser={setUser} />

    {flags.fLogin && <Login user={user} setUser={setUser} flags={flags} setFlags={setFlags} />}
    {flags.fLogout && <Logout user={user} setUser={setUser} flags={flags} setFlags={setFlags} />}
    {flags.fCalendar && is_auth(user) &&  <Calendar flags={flags} setFlags={setFlags} user={user}  />}
    {flags.fCalendar && !is_auth(user) &&  <WaitingLogin/>}
    {/* ログは履歴が必要なのでツリーから外さないようにしている。 -->storageログにしたので、他と同じに。*/}
    {/* //<Logio show={flags.fLogio}/> */}
    {flags.fEditUserFull && <EditUserFull login={user} flags={flags} setFlags={setFlags}/>}
    {/* {flags.fLogio && <Logio show={true} />} */}
    {flags.fChangePW && <ChangePW user={user} />}
    {/* {flags.fAdminTool && <AdminTool user={user} />} */}
    {flags.fMailTo && <Mailto label={MAIL_LABEL} to={MAIL_TO} subject={MAIL_SUBJECT} body={MAIL_BODY} />}
    {/* {flags.fUpFile && <UpFile flags={flags} setFlags={setFlags} user={user} />} */}
    {/* {flags.fDnFile && <DnFile user={user}/>} */}
    {/* {flags.fUserInfo && <UserInfo user={user} setUser={setUser} />} */}
    {/* {flags.fTesio && <Tesio />} */}
    {/* {flags.fTesicon && <Tesicon />} */}
    {flags.fReport && <Report flags={flags} setFlags={setFlags}/>}
    

  </div>
  </Context.Provider>
  )
}

export default App
